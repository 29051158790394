// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  authServiceUrl: 'https://landing-test.internal.sparbankensyd.se/service',
  ssAPIUrl: 'https://landing-test.internal.sparbankensyd.se/api',
  domain: 'landing-test.internal.sparbankensyd.se',
  settingsServiceUrl: 'https://landing-test.internal.sparbankensyd.se/settings',
  // settingsServiceUrl: 'https://api-settings-test.internal.sparbankensyd.se/service'
  documentUrl: 'https://landing-test.internal.sparbankensyd.se/doc'
}
